<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="devicesList"
        :search="search"
        :loading="loading"
        :options.sync="options"
        :server-items-length="getDevicesTotalCount"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar color="indigo lighten-5" flat>
            <BackButton />
            <v-toolbar-title>Автомати</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              class="shrink mr-10"
              append-icon="mdi-magnify"
              label="Пошук"
              single-line
              hide-details
            ></v-text-field>
            <v-dialog v-model="dialogConnect" max-width="600">
              <v-card>
                <v-card-title>
                  <span class="text-h5">
                    Підключити автомат <span class="font-weight-bold">#{{ clickedDeviceId }}</span> до термінала
                  </span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attr }">
                      <v-icon class="ml-2" v-bind="attr" v-on="on" size="18" color="primary">mdi-information-outline</v-icon>
                    </template>
                    Після підключення нового девайса інформація в моніторингу оновиться через кілька хвилин
                  </v-tooltip>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <PagingSelect
                          v-model.number="selectedTerminal"
                          :options="terminals"
                          :options-meta="terminalsMeta"
                          :options-params="terminalsOptions"
                          item-value="id"
                          label="Оберіть термінал, до якого підключаєте"
                          @change="setConnectionNumber"
                          @input="$v.selectedTerminal.$touch()"
                          @blur="$v.selectedTerminal.$touch()"
                          :error-messages="selectedTerminalErrors"
                        >
                          <template v-slot:selection="{ item }">T{{ item.id }} (IMEI: {{ item.imei }})</template>
                          <template v-slot:item="{ item }">T{{ item.id }} (IMEI: {{ item.imei }})</template>
                        </PagingSelect>
                      </v-col>
                    </v-row>
                    <v-row v-if="selectedTerminal && terminal.devices && terminal.devices.length">
                      <v-col cols="12">
                        <span>Зайняті номери підключень: {{ connectionNumbersString }}</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model.number="connectionNumber"
                          label="Будь ласка, вкажіть незайнятий номер пристрою на терміналі"
                          @input="$v.connectionNumber.$touch()"
                          @blur="$v.connectionNumber.$touch()"
                          :error-messages="connectionNumberErrors"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="saveConnect">Так</v-btn>
                  <v-btn text @click="dialogConnect = false">Нi</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialog">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" small v-bind="attrs" v-on="on"> Додати Автомат </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          v-model.number="editedItem.type"
                          :items="connectedDevicesTypesDictionary"
                          item-text="description"
                          item-value="value"
                          :disabled="isEditModal"
                          label="Тип"
                          @input="$v.editedItem.type.$touch()"
                          @blur="$v.editedItem.type.$touch()"
                          :error-messages="typeErrors"
                        >
                          <template v-slot:selection="{ item }">{{ item.description }} ({{ item.name }})</template>
                          <template v-slot:item="{ item }">{{ item.description }} ({{ item.name }})</template>
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.inventory_number"
                          label="Інвентарний номер"
                          @input="$v.editedItem.inventory_number.$touch()"
                          @blur="$v.editedItem.inventory_number.$touch()"
                          :error-messages="inventoryNumberErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.serial_number"
                          label="Серійний номер"
                          @input="$v.editedItem.serial_number.$touch()"
                          @blur="$v.editedItem.serial_number.$touch()"
                          :error-messages="serialNumberErrors"
                          counter
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.brand"
                          label="Бренд"
                          @input="$v.editedItem.brand.$touch()"
                          @blur="$v.editedItem.brand.$touch()"
                          :error-messages="brandErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.model"
                          label="Модель"
                          @input="$v.editedItem.model.$touch()"
                          @blur="$v.editedItem.model.$touch()"
                          :error-messages="modelErrors"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-if="isEditModal">
                      <v-col cols="12" sm="6" md="4">
                        <v-menu
                          v-model="menuManufacture"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.manufacture_date"
                              label="Дата виробництва"
                              prepend-icon="mdi-calendar"
                              hint="рiк-мiсяць-число"
                              persistent-hint
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="editedItem.manufacture_date" @input="menuManufacture = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-menu
                          v-model="menuPurchase"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.purchase_date"
                              label="Дата інсталяції"
                              prepend-icon="mdi-calendar"
                              hint="рiк-мiсяць-число"
                              persistent-hint
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="editedItem.purchase_date" @input="menuPurchase = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="save">Так</v-btn>
                  <v-btn text @click="close">Нi</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">Автомат буде видалено</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="deleteItemConfirm">Так</v-btn>
                  <v-btn text @click="closeDelete">Нi</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.id="{ item }">
          <router-link :to="{ name: 'DeviceCard', params: { deviceId: item.id } }" class="text-decoration-none">
            <v-tooltip bottom :open-on-focus="false">
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-if="item.status !== null && item.status !== 1"
                  :style="{ color: getListStatusColor(item.status) }"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.id }}
                </span>
                <span v-else v-bind="attrs" v-on="on">{{ item.id }}</span>
              </template>
              <span>{{ getListStatusTitle(item.status) }}</span>
            </v-tooltip>
          </router-link>
        </template>
        <template v-slot:item.type="{ item }">{{ getConnectedDeviceTitle(item.type) }}</template>
        <template v-slot:item.manufacture_date="{ item }">{{ item.manufacture_date | getShortDate }}</template>
        <template v-slot:item.terminal="{ item }">
          <router-link
            v-if="item.terminal"
            :to="{ name: 'TerminalCard', params: { id: currentCompanyId, terminalId: item.terminal.id } }"
            class="text-decoration-none"
          >
            T{{ item.terminal.id }}
          </router-link>
        </template>
        <template v-slot:item.terminal.service_point.address="{ item }">
          <router-link
            v-if="item.terminal && item.terminal.service_point"
            :to="{ name: 'ServicePointCard', params: { id: currentCompanyId, servicePointId: item.terminal.service_point.id } }"
            class="text-decoration-none"
          >
            {{ item.terminal.service_point.address.country }}, {{ item.terminal.service_point.address.city }},
            {{ item.terminal.service_point.address.street }},
            {{ item.terminal.service_point.address.building_number }}
          </router-link>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="!item.terminal && item.status === 1"
                @click="openConnectDialog(item)"
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                small
              >
                mdi-connection
              </v-icon>
            </template>
            <span>Підключити до термінала</span>
          </v-tooltip>
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" @click="editItem(item)" v-bind="attrs" v-on="on"> mdi-pencil-outline </v-icon>
            </template>
            <span>Редагувати</span>
          </v-tooltip>
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" @click="deleteItem(item)" v-bind="attrs" v-on="on"> mdi-delete-outline </v-icon>
            </template>
            <span>Видалити</span>
          </v-tooltip>
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small @click="navigateToDeviceCard(item)" v-bind="attrs" v-on="on"> mdi-eye-outline </v-icon>
            </template>
            <span>Переглянути</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="resetData">Оновити</v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { DEVICE_SCHEMA } from '@/const/apiSchemas'
import { mergeObjects } from '@/helpers/mergeObjects'
import { validationMixin } from 'vuelidate'
import { integer, minValue, required } from 'vuelidate/lib/validators'
import convertDate from '@/mixins/convertDate'
import ConvertStatusesTypes from '@/mixins/convertStatusesTypes'
import resetTableData from '@/mixins/resetTableData'
import BackButton from '@/components/common/BackButton'
import convertDevicesTypes from '@/mixins/convertDevicesTypes'
import sortUtils from '@/mixins/sortUtils'

export default {
  name: 'DevicesList',

  components: {
    BackButton,
    PagingSelect: () => import('@/components/common/PagingSelect'),
  },

  mixins: [validationMixin, convertDate, convertDevicesTypes, resetTableData, ConvertStatusesTypes, sortUtils],

  validations() {
    return {
      editedItem: {
        type: { required },
        inventory_number: { required },
        serial_number: { required },
        brand: { required },
        model: { required },
      },
      selectedTerminal: { required },
      connectionNumber: {
        required,
        integer,
        minValue: minValue(1),
        notInArr: (value) => !this.connectionNumbersArr.includes(value),
      },
    }
  },

  data: () => ({
    dialog: false,
    dialogDelete: false,
    dialogConnect: false,
    search: '',
    loading: false,
    clickedDeviceId: null,
    menuManufacture: false,
    // convertedManufactureDate: '',
    menuPurchase: false,
    // convertedPurchaseDate: '',
    isEditModal: false,
    selectedTerminal: null,
    terminalsOptions: null,
    headers: [
      { text: 'ID', align: 'start', sortable: true, value: 'id' },
      { text: 'Тип', value: 'type', sortable: true },
      { text: 'Бренд', value: 'brand', sortable: true },
      { text: 'Модель', value: 'model', sortable: true },
      { text: 'Інв. номер', value: 'inventory_number', sortable: true },
      { text: 'Дата виробництва', value: 'manufacture_date', sortable: true, align: 'center' },
      { text: 'Підключено до термінала', value: 'terminal', sortable: false, width: '130', align: 'center' },
      { text: 'Торгівельна точка', value: 'terminal.service_point.address', sortable: false },
      { text: '', value: 'actions', sortable: false, width: '125px', align: 'center' },
    ],
    devicesList: [],
    producerList: ['Vending.Land', 'Runolfsson, Hartmann and Schroeder'],
    editedIndex: -1,
    sortBy: '',
    sortDesc: false,
    options: {},
    connectionNumber: null,
    editedItem: { ...DEVICE_SCHEMA },
    defaultItem: { ...DEVICE_SCHEMA },
    connectionNumbersArr: [],
    connectionNumbersString: '',
  }),

  computed: {
    ...mapGetters('devices', ['getDevicesTotalCount']),
    ...mapState('devices', ['devices']),
    ...mapState('terminals', ['terminals', 'terminalsMeta', 'terminal']),
    ...mapState('dictionaries', ['connectedDevicesTypesDictionary']),

    formTitle() {
      return this.editedIndex === -1 ? 'Створити автомат' : 'Редагувати автомат'
    },
    currentCompanyId() {
      return this.$route.params.id
    },
    selectedTerminalErrors() {
      const errors = []
      if (!this.$v.selectedTerminal.$dirty) return errors
      !this.$v.selectedTerminal.required && errors.push('Це поле обов"язкове')
      return errors
    },
    typeErrors() {
      const errors = []
      if (!this.$v.editedItem.type.$dirty) return errors
      !this.$v.editedItem.type.required && errors.push('Це поле обов"язкове')
      return errors
    },
    inventoryNumberErrors() {
      const errors = []
      if (!this.$v.editedItem.inventory_number.$dirty) return errors
      !this.$v.editedItem.inventory_number.required && errors.push('Це поле обов"язкове')
      return errors
    },
    serialNumberErrors() {
      const errors = []
      if (!this.$v.editedItem.serial_number.$dirty) return errors
      !this.$v.editedItem.serial_number.required && errors.push('Це поле обов"язкове')
      return errors
    },
    connectionNumberErrors() {
      const errors = []
      if (!this.$v.connectionNumber.$dirty) return errors
      !this.$v.connectionNumber.required && errors.push('Це поле обов"язкове')
      !this.$v.connectionNumber.integer && errors.push('Введіть, будь ласка, ціле число')
      !this.$v.connectionNumber.notInArr && errors.push('Цей номер вже зайнятий')
      return errors
    },
    brandErrors() {
      const errors = []
      if (!this.$v.editedItem.brand.$dirty) return errors
      !this.$v.editedItem.brand.required && errors.push('Це поле обов"язкове')
      return errors
    },
    modelErrors() {
      const errors = []
      if (!this.$v.editedItem.model.$dirty) return errors
      !this.$v.editedItem.model.required && errors.push('Це поле обов"язкове')
      return errors
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    options: {
      handler() {
        this.paginateTo()
      },
      deep: true,
    },
  },

  async created() {
    await this.initialize()
  },

  methods: {
    ...mapActions('devices', ['loadDevices', 'createNewDevice', 'updateSelectedDevice', 'deleteSelectedDevice']),
    ...mapActions('terminals', ['loadTerminals', 'connectDeviceToTerminal', 'loadSelectedTerminal']),
    ...mapActions('logs', ['displayWarningAlert']),

    async initialize() {
      this.devicesList = [...this.devices]
      this.terminalsOptions = {
        loadingFunction: this.loadTerminals,
        payload: {
          company: this.currentCompanyId,
          forSearch: 1,
        },
      }
    },

    async paginateTo() {
      if (this.currentCompanyId !== 'undefined') {
        this.loading = true
        const payload = {
          company: this.currentCompanyId,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sort: this.sortObject,
        }
        await this.loadDevices(payload)
        await this.initialize()
        this.loading = false
      } else {
        await this.displayWarningAlert({ message: 'Оберiть компанiю' })
        await this.$router.replace('/spa')
      }
    },

    editItem(item) {
      this.editedIndex = this.devicesList.indexOf(item)
      this.editedItem = mergeObjects(this.editedItem, item)
      this.clickedDeviceId = item.id
      this.isEditModal = true
      // this.convertManufactureDate()
      // this.convertPurchaseDate()
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.devicesList.indexOf(item)
      // this.editedItem = Object.assign({}, item)
      this.clickedDeviceId = item.id
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.loading = true
      const payload = {
        companyId: this.currentCompanyId,
        deviceId: this.clickedDeviceId,
      }
      await this.deleteSelectedDevice(payload)
      this.closeDelete()
      await this.paginateTo()
      this.loading = false
    },

    close() {
      this.dialog = false
      this.$v.editedItem.$reset()
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        // this.convertedManufactureDate = ''
        // this.convertedPurchaseDate = ''
        this.isEditModal = false
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      this.loading = true
      // this.editedItem.manufacture_date = this.convertedManufactureDate
      // this.editedItem.purchase_date = this.convertedPurchaseDate
      if (this.editedIndex > -1) {
        const payload = {
          companyId: this.currentCompanyId,
          deviceId: this.clickedDeviceId,
          updatedDevice: this.editedItem,
        }
        await this.updateSelectedDevice(payload)
      } else {
        const payload = {
          companyId: this.currentCompanyId,
          newDevice: this.editedItem,
        }
        await this.createNewDevice(payload)
      }
      this.close()
      await this.paginateTo()
      this.loading = false
    },

    openConnectDialog(item) {
      this.clickedDeviceId = item.id
      this.connectionNumber = null
      this.connectionNumbersArr = []
      this.connectionNumbersString = ''
      this.selectedTerminal = null
      this.dialogConnect = true
      this.$v.$reset()
    },

    async setConnectionNumber(value) {
      function getFreeNumber(arr) {
        if (arr.length === 0 || arr[0] !== 1) {
          return 1
        }
        for (let i = 1; i < arr.length; i++) {
          if (arr[i] - arr[i - 1] > 1) {
            return arr[i - 1] + 1
          }
        }
        return arr[arr.length - 1] + 1
      }
      this.loading = true
      this.selectedTerminal = value
      const payload = {
        companyId: this.currentCompanyId,
        terminalId: this.selectedTerminal.id,
      }
      await this.loadSelectedTerminal(payload)
      this.connectionNumbersArr = this.terminal?.devices?.map((item) => item.connection_number).sort((a, b) => a - b)
      this.connectionNumbersString = this.connectionNumbersArr.join(', ')
      this.connectionNumber = getFreeNumber(this.connectionNumbersArr)
      this.loading = false
    },

    async saveConnect() {
      this.$v.selectedTerminal.$touch()
      this.$v.connectionNumber.$touch()
      if (!this.$v.$anyError) {
        this.loading = true
        const params = {
          companyId: this.currentCompanyId,
          terminalId: this.selectedTerminal.id,
          deviceId: this.clickedDeviceId,
          connectionNumber: {
            connection_number: this.connectionNumber,
          },
        }
        try {
          await this.connectDeviceToTerminal(params)
        } finally {
          await this.paginateTo()
          this.loading = false
          this.dialogConnect = false
        }
      }
    },

    navigateToDeviceCard(item) {
      const clickedDeviceId = item.id
      this.$router.push({
        name: 'DeviceCard',
        params: { deviceId: clickedDeviceId },
      })
    },

    convertManufactureDate() {
      // const date = new Date(this.editedItem.manufacture_date)
      // this.convertedManufactureDate = date.toISOString().split('T')[0]
      // this.convertedManufactureDate = this.editedItem.manufacture_date.split('T')[0]
    },

    convertPurchaseDate() {
      // const date = new Date(this.editedItem.purchase_date)
      // this.convertedPurchaseDate = date.toISOString().split('T')[0]
      // this.convertedPurchaseDate = this.editedItem.purchase_date.split('T')[0]
    },
  },
}
</script>

<style scoped></style>
